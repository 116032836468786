.singleOrderProductContainer {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    /* justify-content: space-between; */
    /* justify-items: center; */
    margin-bottom: 1rem;
}
.singleOrderProductContainer .singleOrderImg {
    width: 100px;
    box-shadow: var(--bs-theme-box-shadow);
}
.singleOrderProductContainer .singleOrderImg .productImg{
    width: 100%;
    height: 100%;
}
.singleOrderProductContainer .singleOrderDetails,.productPriceDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.singleOrderProductContainer .productPriceDiv {
    align-items: flex-end;
}
.singleOrderH {
    margin: 0;
}
.singleOrderP{
    color: var(--bs-gray-600); 
}
@media only screen and (max-width: 992px) {
    .singleOrderProductContainer .singleOrderDetails{
        font-size: 15px;
    }
    .singleOrderDetails .singleOrderH {
        font-size: 13px;
    }
    .singleOrderProductContainer .singleOrderImg {
        width: 80px;

    }
}   
