.stationary-admin {
    margin-top: 1rem;
    position: relative;
}
.addStationaryDiv {
    position: fixed;
    top: 0;
    overflow: auto;
    transform: translateX(-50%, -50%);
    left: 0;
    width: 100%;
    background: white;
    height: 100vh;
    z-index: 999;
    transition: all ease-in-out 0.3s;
}
.addStationaryDiv .addStationary-heading{
    display: flex;
    margin: auto;
    align-items: center;
    width: 80%;
    justify-content: space-between;
}
.addStationaryHeading {
    color: var(--bs-theme-secondary);
}

.addStationaryDiv .addStationary-heading .addStationaryClose {
    height: max-content;
    display: flex;
    align-items: center;
    padding: 5px;
    color: var(--bs-theme-secondary);
    background: transparent;
    border: 2px solid var(--bs-gray-400);
    cursor: pointer;
    transition: all ease-out 0.2s;
    border-radius: 5px;
}
.addStationaryDiv .addStationary-heading .addStationaryClose:hover {
    color: white;
    background: var(--bs-theme-secondary);
    border: 2px solid var(--bs-gray-400);
}

.addStationaryDiv .addStationaryForm {
    margin: auto;
    display: grid;
    gap: 1em;
    width: 80%;
    grid-template-columns: repeat(2, 1fr);
}

.addEdit-stationaryDiv .addStationary-button {
    padding: 0.5em 1em;
    font-weight: 600;
    width: 100%;
    text-align: center;
    background: white;
    text-decoration: none;
    color: var(--bs-theme-secondary);
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px var(--bs-gray-500);
    transition: all ease-in-out 0.3s;
    border: 2px solid var(--bs-theme-secondary);
}
.addEdit-stationaryDiv .addStationary-button:hover {
    color: var(--bs-theme-bg);
    background-color: var(--bs-theme-secondary);
}

.stationary-admin .admin-stationaryItems-div{
    display: grid;
    margin-top: 2rem;
    grid-template-columns: repeat(4,1fr);
    gap: 0 1rem ;
}

/* .delete-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    filter: opacity(0.4);
    z-index: 999;
} */

.stationary-admin .deleteForm {
    position: fixed;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    padding: 0.5rem;
    height: max-content;
    width: 30%;
    background: #fff;
    z-index: 999;
}


.deleteForm .deleteFormClose{
    height: max-content;
    display: flex;
    align-items: center;
    align-self: flex-end;
    padding: 5px;
    color: var(--bs-theme-secondary);
    background: transparent;
    border: 2px solid var(--bs-gray-400);
    cursor: pointer;
    transition: all ease-out 0.2s;
    border-radius: 5px;
}
.deleteForm .deleteFormContent{
    width: 100%;
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 1rem;
}
.deleteForm .deleteFormContent .deleteFormHeading{
    font-size: 1.5rem;
    color: var(--bs-theme-secondary);
    align-self: center;
}
.deleteForm .deleteFormContent .deleteFormButton{
    display:grid;
    gap: 1rem;
}
.deleteForm .deleteFormContent .deleteFormButton .deleteBoxBtn{
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid var(--bs-gray-400);
}
.deleteFormButton .deleteYesBtn{
    color: var(--bs-theme-bg);
    background: var(--bs-theme-secondary);
}
.deleteFormButton .deleteNoBtn{
    color: var(--bs-theme-bg);
    background: var(--bs-danger);
}


@media only screen and (max-width: 992px){
    .addStationaryDiv .addStationaryForm {
        grid-template-columns: repeat(1, 1fr);
    }
    .stationary-admin .admin-stationaryItems-div{
        grid-template-columns: repeat(2,1fr);
        gap: 1rem ;
    }
}

