.addBooksContainer {
    margin: 1em auto 5em;
}
.addBooksForm {
    width: 80%;
    margin: auto;
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2,1fr);
}
.addBooksForm .addBooksInp{
    padding: 0.4em 1em;
    font-size: 1rem;
    outline: none;
    border-radius: 5px;
    border:  2px solid var(--bs-secondary);
}
.addBookBtn ,.addBookDesc,.addBooksFile {
    grid-column: span 2;
}
.addBooksFile {
    background: var(--bs-white);
}


.addBookBtn {
    padding: 10px 0;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    border: none;
    cursor: pointer;
    background: var(--bs-secondary);
    font-weight: 600;
}
.addBookBtn:hover {
   background:  #8d73dd;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
} 
@media only screen and (max-width: 992px) {
    .addBooksForm { 
        width: 90%;
        grid-template-columns: repeat(1,1fr);
    }
    .addBookBtn ,.addBookDesc,.addBooksFile {
        grid-column: unset;
    }
}