.viewAllComponent .productDiv{
    display: grid;
    column-gap: 5px;
    grid-template-columns: repeat(5,1fr);
}

@media only screen and (max-width: 992px) { 
    .viewAllComponent .productDiv{
        display: grid;
        column-gap: 5px;
        grid-template-columns: repeat(2,1fr);
    }
}