.cartDetailsBox {
    /* background: cyan; */
    background: var(--bs-white);
    display: flex;
    padding: 1.5em;
    margin-bottom: 2em;
    box-shadow:  var(--bs-theme-box-shadow);
    border-radius: 5px;
    width: 90%;
}

.cartDetailsBox .bookImg {
    width: 150px;
    box-shadow: var(--bs-theme-box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bs-theme-bg);
    padding: 0.2rem;
}
.cartDetailsBox .bookImg img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}


.cartDetailsBox .bookCartDetails {
    display: flex;
    flex-grow: 1;
    padding: 0 1em;
    flex-direction: column;
}
.cartDetailsBox .bookCartDetails .bookCartPrimary{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    /* height: 50%; */
}
.cartDetailsBox .bookCartDetails .bookCartPrimary button{
   align-self: flex-start;
   color: var(--bs-theme-secondary);
   background: transparent;
   border: 2px solid var(--bs-gray-400);
   display: flex;
   cursor: pointer;
   padding: 5px;
   border-radius: 5px;
   align-items: center;
}

.cartDetailsBox .bookCartDetails .bookCartSecondary{
    display: flex;
    height: 50%;
    margin: 1rem 0 0;
    flex-direction: column;
    /* background: lightcyan; */
    justify-content: space-between;
    
}
.cartDetailsBox .bookCartDetails .bookCartSecondary .bookCartPrice{
    display: flex;
}

.cartDetailsBox .bookCartDetails .bookCartSecondary .bookCartPrice p:nth-child(2) {
    font-weight: bold;
    color: var(--bs-theme-secondary);
}

.cartDetailsBox .bookCartDetails .bookCartSecondary .bookCartIncrease{
    display: flex;
    align-self: flex-end;
}
.cartDetailsBox .bookCartDetails .bookCartSecondary .bookCartIncrease .bookCartIncrease-btn{
    /* box-shadow: var(--bs-theme-box-shadow); */
    margin-left: 1em;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    color: var(--bs-theme-secondary);
    display: flex;
    background: transparent;
    border: 2px solid var(--bs-gray-400);
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.cartDetailsBox .bookCartDetails .bookCartSecondary .bookCartIncrease .bookCartIncrease-btn:hover {
    background: var(--bs-theme-secondary);
    color: var(--bs-white);
}

@media only screen and (max-width: 992px) {
    .cartDetailsBox { 
        width: 100%;
    }
}
