.singleOrderContainer {
    background: var(--bs-white);
    box-shadow: var(--bs-theme-box-shadow);
    border-radius: 5px;
    width: 70%;
    margin: 1rem auto;
    /* padding: 1rem; */
}
.singleOrderContainer .singleOrderDivHeader {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
}
.singleOrderContainer .singleOrderDivBody {
    margin: 1rem 0 0;
}
.singleOrderDivHeader .singleOrderInvoice,.singleOrderDownload {
    background: transparent;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
}
.pdf-container {
    position: absolute;
    left: -150rem;
}
.singleOrderDivHeader .singleOrderDiv {
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.singleOrderDivHeader .singleOrderInvoice {
    border: 2px solid var(--bs-secondary);
    border-radius: 5px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    align-items: center;
    justify-content: space-evenly;
    color: var(--bs-theme-secondary);
}
.singleOrderDivHeader .singleOrderInvoice:hover {
    border: 2px solid var(--bs  -secondary);
    border-radius: 5px;
    box-shadow: var(--bs-theme-box-shadow);
    background-color: var(--bs-secondary);
    color: var(--bs-theme-secondary);
}
.singleOrderDivHeader .singleOrderDownload {
    border-radius: 5px;
    margin-left: 1rem;
    border: 2px solid var(--bs-secondary);
    background-color: var(--bs-secondary);
    color: var(--bs-theme-secondary);
}
.singleOrderDivHeader .singleOrderDownload:hover {
    box-shadow: var(--bs-theme-box-shadow);
    border: 2px solid var(--bs-theme-secondary);
    background-color: var(--bs-theme-secondary);
    color: var(--bs-secondary);
}
.singleOrderNav .singleNavBtn {
    padding: 0 1rem;
}
.singleNavBtn .singleOrderSummaryBtn,.singleOrderDeliveryBtn {
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.singleOrderBorderNone{
    border: none;
    font-size: 1rem;
    color: var(--bs-gray-600);
}
.singleOrderBorderBottom{
    border: none;
    font-size: 1rem;
    color: var(--bs-theme-secondary);
    font-weight: bold;
    border-bottom: 4px solid var(--bs-theme-secondary);
}
/* .showSection .showSectionDelivery {
    height: 50vh;
} */
.singleOrderContainer .singleOrderDivHeader .showDelivery{
    font-weight: 500;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 5px;
    margin: 0 0 0 1rem;
}
.showSection .showSectionSummary {
    overflow: auto;
    height: 50vh;
    padding: 0 2rem;
}
.statusPending{
    border:2px solid lightgray;
    background-color: lightgray;
    color: gray;
}
.statusProcessing{
    border:4px solid #FFA500;
    color: #bb5502;
    background-color: #FFA500;
}
.statusDelivered{
    border:4px solid lightgreen;
    background-color: lightgreen;
    color: green;
}

/* .showSection .showSectionDelivery .showDelivery,.deliveryCircle{
    display: flex;
    align-items: center;
    gap: 10px;
} */
.showSectionDelivery .orderStatusContainer,.orderDeliveryContainer {
    margin: 1rem 0;
    border-radius: 5px;
    background: var(--bs-white);
    padding: 10px;
    border: 3px solid var(--bs-secondary);
}  
.orderDeliveryContainer .orderDeliveryOtpContainer{
    display: flex;
    gap: 2rem;
    margin: 10px 0 5px 0;
    justify-content: space-between;
}
.orderDeliveryOtpContainer .deliveryOtpSendContainer,.deliveryEnterOtpContainer {
    display: flex;
    width: 100%;
    gap: 2rem;
}
.orderDeliveryOtpContainer .deliveryOtpSendContainer .deliveryUserOtp{
    width: 50%;
    text-align: center;
    border-radius: 5px;
    padding: 10px 5px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    cursor: pointer;
    background-color: lightgray;
    color: gray;
    font-weight: 600;
    border: none;
    /* border: 2px solid gray; */
}
.orderDeliveryOtpContainer .deliveryOtpSendContainer .deliveryUserSend{
    flex-grow: 1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 5px;
    color: var(--bs-theme-bg);
    font-weight: 600;
    letter-spacing: 3px;
    background-color: #7E41B3;
}
.orderDeliveryOtpContainer .deliveryOtpSendContainer .deliveryUserSend:hover{
    background-color: var(--bs-theme-secondary);
}
.deliveryEnterOtpContainer .deliveryUserOtp-enter{
    width: 50%;
    text-align: center;
    border-radius: 5px;
    padding: 10px 5px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    cursor: pointer;
    background-color: #e7d4ff;
    color: #8b2eef;
    font-weight: 600;
    border: none;
}
.deliveryEnterOtpContainer .deliveryUserOtp-enter::placeholder{ 
    color: #8b2eef;
}
.deliveryEnterOtpContainer .deliveryUserSubmit{
    flex-grow: 1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 5px;
    color: var(--bs-theme-bg);
    font-weight: 600;
    letter-spacing: 3px;
    background-color: #7E41B3;
}
.showSectionDelivery .orderStatusContainer .orderStatusChangeContainer {
    display: flex;
    gap: 2rem;
    margin: 10px 0 5px 0;
    justify-content: space-between;
} 
.orderStatusContainer .orderStatusChangeContainer .orderStatusChange {
    width: 60%;
    border-radius: 5px;
    padding: 0 5px;
    outline: none;
    cursor: pointer;
    color: var(--bs-theme-secondary);
    font-weight: 600;
    border: 2px solid var(--bs-secondary);
}
.orderStatusContainer .orderStatusChangeContainer .orderStatusChange .orderStatusChangeValue{
    color: var(--bs-theme-secondary);
    cursor: pointer; 
}
.orderStatusContainer .orderStatusChangeContainer .orderStatusChange .orderStatusChangeValue:hover{
    /* color: var(--bs-theme-secondary); */
    cursor: pointer; 
}
.orderStatusChangeContainer .orderStatusSaveBtn {
    flex-grow: 1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    /* border: 2px solid var(--bs-theme-secondary); */
    border-radius: 5px;
    border: none;
    box-shadow: var(--bs-theme-box-shadow);
    padding: 5px;
    color: var(--bs-theme-bg);
    font-weight: 600;
    letter-spacing: 3px;
    background-color: #7E41B3;
}
.orderStatusChangeContainer .orderStatusSaveBtn:hover {
    /* border: 2px solid var(--bs-secondary); */
    background-color: var(--bs-theme-secondary);
}



.showSection .showSectionDelivery .deliveryContainer {
    margin-top: 1rem;
}
.deliveryContainer .deliveryContainerTable {
    width: 100%;
}
.deliveryContainer .deliveryContainerTable .deliveryTableHeader{
    color: var(--bs-theme-secondary);
    font-size: 14px !important;
}
.deliveryContainer .deliveryContainerTable .deliveryTableBody{
    color: var(--bs-theme-secondary);
}

/* .deliveryCircle {
    display: flex;
    align-items: center;
} */
.singleOrderDivBodyContainer .singleOrderNav .showSection {
    padding: 1rem;
    background-color: var(--bs-theme-bg);
}


.singleOrderTotalDiv {
    padding: 0 1rem;
}
.singleOrderTotalContainer .singleOrderTotal{
    display: flex;
    padding: 1rem 0rem;
    justify-content: space-between;
}
.singleOrderTotalContainer .singleOrderSum {
    background: var(--bs-secondary);
    padding: 1rem 1rem;
    color: var(--bs-theme-secondary);
    border-radius: 0 0 5px 5px ;
}
@media only screen and (max-width: 992px) {
    .singleOrderContainer {
        width: 100%;
    } 
    .deliveryContainer .deliveryContainerTable .deliveryTableHeader{
        color: var(--bs-theme-secondary);
        font-size: 18px !important;
    }
    .singleOrderContainer .singleOrderDivHeader {
        flex-direction: column;
        gap: 1rem;
    }
    .deliveryContainer .deliveryContainerTable .deliveryTableBody{
        font-size:1rem;
        overflow: hidden;
    }
    .singleOrderContainer .singleOrderDivHeader .showDelivery{
        font-size: 15px;
    }
    /* .singleOrderDivBodyContainer .singleOrderNav .showSection {
        padding: 1rem 0;
    } */
    .showSection .showSectionSummary {
        padding: 0 1rem;
    }
    .orderDeliveryOtpContainer .deliveryOtpSendContainer,.deliveryEnterOtpContainer {
        flex-direction: column;
        gap: 0.5rem;
    }
    .orderDeliveryOtpContainer .deliveryOtpSendContainer .deliveryUserOtp{
        width: 100%;
    }.deliveryEnterOtpContainer .deliveryUserOtp-enter{
        width: 100%;
    }
    

}