.uploadBookListComponent {
    width: 80%;
    margin: 0 auto 1rem;
}
.uploadBooksHeader {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--bs-theme-secondary);
}
.uploadBookListComponent .uploadBookFormContainer {
    border: 2px solid var(--bs-secondary);
    padding: 2rem;
    margin: 1rem 0 0;
}
.uploadBookListComponent .uploadBookForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.uploadBookListComponent .uploadBookForm .uploadBookListGroup {
    display: flex;
    color: var(--bs-theme-secondary);
    flex-direction: column;
}
.uploadBookListComponent .uploadBookForm .uploadBookListGroup .uploadBookInputImg{
   width: 100%;
    border-radius: 5px;
   background: var(--bs-white);
   border: 3px solid var(--bs-secondary);

}
.uploadBooksInput{
    cursor: not-allowed;
    border-radius: 5px;
    padding: 10px 10px;
    background: var(--bs-gray-200);
    border: 2px solid var(--bs-gray-400);
}
.uploadBookListComponent .uploadBookBtn {
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
    letter-spacing: 4px;
    font-weight: 500;
    cursor: pointer;
    border: 2px solid var(--bs-secondary);
    font-size: 1rem;
    color: var(--bs-theme-secondary);
    background: var(--bs-secondary);
    margin: 1rem 0 0;
    transition: all 0.3s ease-out;
}
.uploadBookListComponent .uploadBookBtn:hover {
    border: 2px solid #7E41B3;
    background-color: #7E41B3;
    color: var(--bs-gray-400);
}
@media only screen and (max-width: 992px) { 
    .uploadBookListComponent .uploadBookFormContainer {
        padding: 1rem;
    }
    .uploadBookListComponent {
        width: 100%;
    }

}   