.benefitConatiner{
    display: flex;
    text-align: center;
    box-shadow: var(--bs-theme-box-shadow);
    flex-direction: column;
    background: var(--bs-white);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1rem 1rem;
}

.benefitConatiner .benefitIcon {
    display: flex;
    justify-content: center;
    background: var(--bs-gray-200);
    width: fit-content;
    border-radius: 50%;
    align-self: center;
    font-size: 1.5em;
    padding: 20px;
}

.benefitConatiner .benefitDesc{
    font-size: 15px;
    /* margin: 1rem 0; */
}
.benefitConatiner h4{
    margin-bottom: 0;
}
@media only screen and (max-width: 992px) { 
    .benefitConatiner .benefitDesc{
        margin: 1rem 0;
    }
}