.noteBooksCard{
    color: var(--bs-theme-secondary);
    border-radius: 10px;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    box-shadow: var(--bs-theme-box-shadow);
}
.noteBooksCard .noteBooksImgDiv {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.noteBooksCard .noteBooksTitleDiv {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* .noteBooksCard .noteBooksTitleDiv .noteBooksTitle{

} */
@media only screen and (max-width: 992px) {
    .noteBooksCard{
        border-radius: 5px;
    } 
    .noteBooksCard .noteBooksImgDiv {
        border-radius: 5px;
    }    
    .noteBooksCard .noteBooksTitleDiv {
        gap: 5px;
    }
}