.orderConfirmContainer {
    /* border: 2px solid var(--bs-secondary); */
    width: 60%;
    height: 90vh;
    overflow: auto;
    border-radius: 5px;
    padding: 0.5rem 2rem;
    position: fixed;
    left: 50%;
    border: 2px solid var(--bs-theme-secondary);
    z-index: 1000;
    top: 50%;
    background: var(--bs-theme-bg);
    transform: translate(-50%,-50%);
}
.orderConfirmContainer::before {
    content: "";
    z-index: 999;
    background-color: var(--bs-gray-700);
    width: 100%;
    height: 100vh;
}

.orderInputBox {
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
}
.orderInput {
    height: 2rem;
}
.orderButtonBox {
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
    margin-top: 1rem;
}
.confirmOrderButton {
    padding: 10px 0;
    cursor: pointer;
    width: 30%;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    border: 2px solid #7E41B3;
    border-radius: 5px;
    color: var(--bs-white);
    background: var(--bs-theme-secondary);
}
.confirmOrderButton:hover {
    background: #7E41B3;
}
.cancelOrderButton{
    padding: 10px 0;
    cursor: pointer;
    width: 30%;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    border: 2px solid #7E41B3;
    border-radius: 5px;
    background: var(--bs-white);
    color: var(--bs-theme-secondary);
}
.cancelOrderButton:hover {
    background: #7E41B3;
    /* color: var(--bs-theme-secondary); */
    color: var(--bs-white);
}

.orderBoxTitle {
    margin: 0;
}
.displayOrderBox {
    display: block;
}
.hideOrderBox {
    display: none;
}
.selectedBooksTableContainer {
    border-radius: 10px;
    box-shadow: var(--bs-theme-box-shadow);
    margin-bottom: 2em;
    /* padding: 10px 10px 0; */
}
.selectedBooksTableContainer .selectedBooksTable {
    border-collapse: collapse;
    overflow-x: auto;
    width: 100%;
}

.selectedBooksTable .selectedBooksTableHead{
    background: var(--bs-white);  
}
.selectedBooksTable .selectedBooksTableHead .selectedBooksTableHeadTr{
    color: var(--bs-theme-secondary);
}
.selectedBooksTable .selectedBooksTableHead .selectedBooksTableHeadTr th:first-child{
    padding: 10px;
}
.selectedBooksTable .selectedBooksTableHead .selectedBooksTableHeadTr th:not(:first-child){
    text-align: left;
}
.selectedBooksTable .selectedBooksTableHead .selectedBooksTableHeadTr th:last-child{
    text-align: center;
}

.selectedBooksTable .selectedBooksTableBody{
    background: var(--bs-white);
}

.selectedBooksTable .selectedBooksTableBody .selectedBooksTableBodyTr{
    font-weight: 500;
}
.selectedBooksTable .selectedBooksTableBody .selectedBooksTableBodyTr td{ 
    padding: 15px 20px;
}
.selectedBooksTable .selectedBooksTableBody tr:nth-child(odd){
    background: #e6e6fa;
}
.selectedBooksTable .selectedBooksTableBody .selectedBooksTableBodyTr td:last-child{
    text-align: center;
}
.booksAddBtn, .booksSubBtn {
    align-items: center;
    background: #0000;
    cursor: pointer;
    /* border: 2px solid var(--bs-theme-secondary); */
    border: none;
    border-radius: 5px;
    color: var(--bs-theme-secondary);
    display: inline-flex;
}

@media only screen and (max-width: 992px) {
    .orderConfirmContainer { 
        width: 90%;
    }
    .selectedBooksTableContainer .selectedBooksTable {
        width: max-content;
    }
}