.homeContainer .homeSectionFirst{
    display: flex;
    gap: 2em;
}

.homeContainer .categoriesBar {
    width: 20%;
    display: flex;
    background: var(--bs-white);
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: var(--bs-theme-box-shadow);
}
.homeContainer .categoriesBar a{
    box-sizing: border-box;
    border-radius: 5px;
    text-decoration:  none;
    width: 100%;
    padding-left: 2em;
    overflow: hidden;
    color: var(--bs-theme-secondary);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
}

.homeContainer .categoriesBar a:hover{
    background: #BC8CF2;
    transition: all ease-in 0.3s;
}

.homeContainer .productSlider {
    background: var(--bs-white);
    border-radius: 5px;
    box-sizing: border-box;
    width: calc(100% - (20% + 2em));
    box-shadow: var(--bs-theme-box-shadow);
}
.homeContainer .homeSectionFirst  .productSlider .sliderDiv .sliderDivContent h1{
    margin: 0;
    line-height: 1em;
    margin-bottom: 1em;
}
.homeContainer .homeSectionFirst  .productSlider .sliderDiv .sliderDivContent button{
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 1.5em 0;
    color: var(--bs-white);
    background: var(--bs-theme-secondary);
    box-shadow: var(--bs-theme-box-shadow);
}
.homeContainer .homeSectionFirst  .productSlider .sliderDivImg {
    height: 440px;
}
.homeContainer .homeSectionFirst  .productSlider .sliderDivImg img{
    width: 100%;
    border-radius: 5px;
    /* height: 100%; */
    object-fit: cover;
}

.homeContainer .homeSectionSecond .sectionHeader {
    display: flex;
    margin: 0;
    /* margin:  2em 0 0 0; */
    align-items: center;
}
.homeContainer .homeSectionSecond .productDiv {
  display: grid;
  margin: 1rem 0 0;
  gap:5px ;
  grid-template-columns: repeat(5,1fr);   
}

.homeContainer .section-viewall {
    display: flex;
    align-items: center;
    justify-content: center;
}
.homeContainer .section-viewall .viewall-btn{
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 1rem;
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: 600;
    color: var(--bs-theme-secondary);
    border: 2px solid var(--bs-theme-secondary);
    transition: 0.3s ease-out;
}
.homeContainer .section-viewall .viewall-btn:hover {
    color: #F5F5F5;
    background-color: var(--bs-theme-secondary);
}
.homeContainer .homeSectionThird {
    margin: 5em 0 3em;
    display: grid;
    gap: 2em;
    grid-template-columns: repeat(4,1fr);
}
.homeContainer .homeSectionFourth {
    margin: 2rem 0;
}

.homeContainer .homeSectionFourth h3{
    display:flex;
    margin: 0;
    /* align-items: center; */
}
.slick-next, .slick-prev {
    width: unset;
}
.slick-prev:before,.slick-next:before  {
    color: lightgray;
    font-size: 30px;
}
.whatsaap-icon-container {
    position: fixed;
    filter: drop-shadow(0px 0px 10px #86e965);
    bottom:2rem;
    right: 1rem;
    z-index: 999;
    animation: whatsappAnimation 1s infinite 0s  ease-in-out;
}
.whatsapp-icon {
    width: 80px;
}
.homeContainer .homeSectionFifth {
    display: flex;
    margin: 2rem 0 4rem;
    gap: 2rem;
}

@keyframes whatsappAnimation {
    from{
        scale: 1.2;
    }
    to{
        scale: 1;
    }
}

@media only screen and (max-width: 992px) {
    .homeContainer .productSlider {
        width: 100%;
    }
    .homeContainer .homeSectionFirst  .productSlider .sliderDiv .sliderDivContent{ 
        font-size: 10px;
    }
    .homeContainer .homeSectionFirst  .productSlider .sliderDiv .sliderDivContent h1{
        font-size: 1.1rem;
    }
    .homeContainer .homeSectionFirst  .productSlider .sliderDiv .sliderDivContent p{ 
        display: none;
    }
    .homeContainer .homeSectionFirst  .productSlider .sliderDiv .sliderDivContent button{ 
        font-size: 11px;
        margin: 2em 0;
    }

    .slick-prev:before,.slick-next:before  {
        display: none;
    } 
    .homeSectionFourth .slick-slider .slick-list .slick-track {
        width: 3500px !important; 
    }
    /* .homeSectionFourth .slick-slider .slick-list .slick-track div {
        width: unset !important;
    } */
    .homeContainer .homeSectionThird {
        grid-template-columns: repeat(1,1fr);
    }
    .homeContainer .homeSectionSecond {
        margin: 0 0 1.5rem;
    }
    .homeContainer .homeSectionSecond .productDiv {
        grid-template-columns: repeat(2,1fr);  
        row-gap: 20px;
    }

    .whatsapp-icon {
        width: 60px;
    }
    .whatsaap-icon-container {
        bottom:1.5rem;
        right: 1rem;
    }
    .homeContainer .homeSectionFirst .productSlider .sliderDivImg {
        height: 100%;
    }
    .homeContainer .homeSectionFifth {
        gap: 10px;
        margin: 3rem 0 4rem;
    }
}