.schoolCardContainer {
    box-shadow: var(--bs-theme-box-shadow);
    display: flex;
    text-decoration: none;
    padding: 0.5em 0.5em  1em 0.5em;/* padding: 0em 0  1em 0; */
    border-radius: 10px;
    margin: 10px 5px;
    color: var(--bs-theme-secondary);
    background: var(--bs-white);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.schoolCardContainer div{
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
}
.schoolCardContainer img{
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
    border-radius: 10px;
}
.schoolCardContainer h3,p{
    margin: 0;
}

.schoolCardContainer .schoolCardContainerTitle {
    /* height: 70px; */
    font-size: 20px;
    /* text-align: center; */
}
@media only screen and (max-width: 992px) { 
    .schoolCardContainer {
        margin: 10px 0px;
        padding: 0em 0em 1em 0em;
    }
    .schoolCardContainer div{
        height: 120px;
        width: 100%;
    }
    .schoolCardContainer h3,p{
        font-size: 14px !important;
    }
}


