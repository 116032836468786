.navBarContainer {
    /* background: cyan; */
    padding: 10px 0;
    background: var(--bs-white);
    box-shadow: 0px 1px 10px var(--bs-gray-400);
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.navBarContainer .navSection1{
    width: 90%;
    margin: auto;
    padding: 20px 0;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.navBarContainer .navSection1 .logo{
    width: 120px;
}
.navBarContainer .navSection1 .logo img{
    width: 100%;
}
.navSection1 .mob-searchContainer {
    display: flex;
    gap:2rem;
    align-items: center;
}


.navBarContainer .navSection1 .searchInput input{
    width:40rem;
    padding-left: 20px;
    font-family: "Poppins", sans-serif;
    height: 40px;
    border-radius: 20px;
}

.navBarContainer .navSection1 .usericons{
    font-size: 30px;
    /* padding: 10px 15px; */
    /* background: var(--bs-gray-200); */
    border-radius: 50%;
    /* margin-left: 1em; */
    /* border:  1px solid black; */
}
.navBarContainer .navSection2 .usericons .nav-usericons{
    font-size: 16px !important;
}
.navSection1 .regBtn {
    display: flex;
    gap: 0.5em;
}
.navSection1 .regBtn a {
    padding: 5px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;

}
.navSection1 .regBtn a:nth-child(1) {
    color: var(--bs-dark);
    /* background-color: rgba(64, 87, 109, .07); */
    background-color: var(--bs-gray-200);
}
.navSection1 .regBtn a:nth-child(2) {
    color: var(--bs-white);
    background-color: #64398c;
}
.navSection1 .regBtn a:nth-child(1):hover {
    background: var(--bs-gray-400);
}
.navSection1 .regBtn a:nth-child(2):hover {
    background: var(--bs-theme-secondary);
}

.navBarContainer .navSection2{
    width: 90%;
    transition: all 0.2s ease-in-out;
    padding: 0 0 10px;
    display: flex;
    margin: auto;
    transition: 0.70s ease-in-out;
}

.navBarContainer .navSection2 .categoriesButton button{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    gap: 0.5em;
    color: var(--bs-theme-secondary);
    font-weight: 600;
    border-radius: 5px;

    background: var(--bs-theme-bg);
    border: none;
}
.navBarContainer .navSection2 .pageLinks{
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.navBarContainer .navSection2 .pageLinks .sidenav-profile{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}
.navBarContainer .navSection2 .pageLinks a{
    text-decoration: none;
    display: flex;
    position: relative;
    gap: 7px;
    align-items: center;
    transition: ease 0.3s all;
    font-weight: 500;
    color: var(--bs-theme-secondary);
}
.navBarContainer .navSection2 .pageLinks a::after {
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: ease 0.3s all;
    border-radius: 10px;
    content: '';
    background: var(--bs-secondary);
    width: 0;
    height: 5px;
}
.navBarContainer .navSection2 .pageLinks a:hover::after {
    width: 100%;
}
.navBarContainer .navSection2 .pageLinks a:hover{
    color: var(--bs-secondary);
}
.navBarContainer .navSection2 .pageLinks a:not(:first-child) {
    margin-left: 1em;
}
/* .navBarContainer .navSection2 .pageLinks a .sidenav-icons { 
    display: none;
 } */
.navBarContainer .hamburger {
    display: none;
}
.showNavMenu{
    left: 0 !important;
}
.hideNavMenu{
    left: -200vw !important;
}
.hamburgerZindex {
    z-index: 1010;
}
.mobileNavSearch {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.navBarContainer .navSection3 {
    display: none;
    width: 90%;
    margin: 12px auto 8px;
    height: 35px;
}
.navSection3 .mobile-search {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-weight: 500;
}
.navSection3 .mobile-search .mobile-search-input{ 
    width: 100%;
    height: 100%;
    padding: 5px;
    color: var(--bs-theme-secondary);
    outline: none;
    border: 2px solid var(--bs-secondary);
    border-radius: 5px;
}
.navSection3 .mobile-search .mobile-search-input::placeholder { 
 color: var(--bs-theme-secondary);
}
.navSection3 .mobile-search .mobile-search-icon {
    margin-left: -1.8rem;
    width: 10%;
    display: flex;
    background-color: white;
    color: var(--bs-theme-secondary);
}
.navSection3 .upload-booklist {
    font-size: 12px;
    border-radius: 5px;
    align-items: center;
    text-decoration: none;
    display: flex;
    padding: 0 8px;
    margin-left: 8px;
    box-shadow: var(--bs-theme-box-shadow);
    font-weight: 500;
    background-color: var(--bs-secondary);
    border: 2px solid var(--bs-secondary);
    color: var(--bs-theme-secondary);
}
.nav-flex {
    display: flex;
}
/* .navBarContainer .navSection3 .nav-search{
    width: 100%;
    margin: auto;
    font-size: 1rem;
    color: var(--bs-theme-secondary);
    padding: 5px;
    outline: none;
    border: 2px solid var(--bs-theme-secondary);
    border-radius: 5px;
} */
/* .navBarContainer .navSection3 .nav-searchBtn{
    width: 100%;
    margin: 1rem auto 0;
    display: flex;
    gap: 1rem;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    background-color: var(--bs-secondary);
    font-size: 1rem;
    color: var(--bs-theme-secondary);
    padding: 5px;
    outline: none;
    border: none;
    border-radius: 5px;
} */
/* .sidenav-hr {
    background: var(--bs-theme-secondary);
} */
@media only screen and (max-width: 992px) {
    /* .navBarContainer .navSection1 .logo{
        width: 100px;
    } */
    .navBarContainer .navSection3 {
        display: flex;
    }
    .navBarContainer .navSection2 .pageLinks a .sidenav-icons { 
        display: block;
     }
    .navBarContainer .navSection1{
        /* width: 90%;
        margin: auto;
        padding: 20px 0;
        align-items: center;
        justify-content: space-between;
        display: flex; */
        padding: 0;
    }
    .navBarContainer .sidenav-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9;
        /* transition: 0.65s ease; */
        background-color: rgba(0, 0, 0, 0.35);
    }
    .navBarContainer .navSection2 .pageLinks a:not(:first-child) {
        margin-left: unset;
    }
    .navBarContainer .hamburger {
        color: var(--bs-theme-secondary);
        display: flex;
        /* font-size: 40px; */
        /* position: fixed; */
        /* display: flex; */
        /* margin: 25px 0 0;
        flex-direction: column;
        align-items: center;
        top: 5vh;
        width: 2rem;
        left: 3%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        cursor: pointer; */
    }
    .navBarContainer .hamburger .line{
        height: 3px;
        width: 100%;
        background-color: #000;
        margin: 3px 0;
        -webkit-transition: 0.3s all ease;
        transition: 0.3s all ease;
    }
    .navSection1 .regBtn a:nth-child(2) {
        display: none;
    }

    .navBarContainer .navSection2{ 
        width: 70%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        position: fixed;
        background-color: var(--bs-white);
        height: 100vh;
        z-index: 1009;
        top: 0;
    }
    .navBarContainer .navSection2 .pageLinks{ 
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .navBarContainer .navSection2 .pageLinks .sidenav-hr {
        height: 2px ;
        /* border-top: 2px solid var(--bs-theme-secondary); */
        width: 100%;
        margin: 1rem 0 0;
    }
   
    .navBarContainer .navSection2 .pageLinks a{
        padding: 1rem 0 0 1rem;
        width: 100%;
        font-size: 16px;
    }

}


