.adminContainer {
    /* height: 80vh; */
    margin:0 0 5rem; 
}
.adminBtns {
    display: grid;
    align-items: stretch;
    row-gap:  2rem;
    justify-items: center;
    grid-template-columns: repeat(3,1fr);
}
.adminBtns .adminLinks {
    padding: 0.5em 1em;
    font-weight: 600;
    width: 60%;
    text-align: center;
    background: white;
    text-decoration: none;
    color: var(--bs-theme-secondary);
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px var(--bs-gray-500);
    transition: all ease-in-out 0.3s;
    border: 2px solid var(--bs-theme-secondary);
}
.adminLinks:hover {
    color: var(--bs-theme-bg);
    background-color: var(--bs-theme-secondary);
}
@media only screen and (max-width: 992px) {
    .adminBtns { 
        grid-template-columns: repeat(1,1fr);
    }
}