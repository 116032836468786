.loginContainer {
    width: 50%;
    margin: 3em auto  3em;
    text-align: center;
}
.loginForm {
    margin:  3rem 0;
}
.loginContainer form .loginInp {
    border-radius: 5px;
    border: none;
    outline: 2px solid var(--bs-secondary);
    padding: 15px 20px;
}
.loginContainer form .signUp-text {
    text-align: left;
    margin: 2rem 0 15px;
    color: var(--bs-theme-secondary);
}
/* .loginContainer form .loginInp:focus {
    outline: 2px solid var(--bs-secondary);
} */
.loginContainer form .loginInp {
    display: block;
    margin:  1rem 0 0;
    width: 100%;
}

.loginContainer form .loginBtn {
    display: block;
    width: 100%;
    padding: 10px 0;
    letter-spacing: 2px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    border: none;
    cursor: pointer;
    background: var(--bs-secondary);
    font-weight: 600;
}
.loginContainer form .loginBtn:hover {
    background: #8d73dd;
}
@media only screen and (max-width: 992px) { 
    .loginContainer {
        width: 100%;
        text-align: center;
    }
    .loginContainer form .loginBtn { 
        font-size: 1rem;
    }  
}