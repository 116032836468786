.addSchoolComponent {
    text-align: center;
    margin: 0 0 5em;
}
.addSchoolForm {
    width: 80%;
    gap: 1em;
    margin:3rem auto 0; 
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.addSchoolForm .addSchoolInp {
    padding: 0.5em 1em;
    font-size: 1rem;
    outline: none;
    border-radius: 5px;
    border: 2px solid var(--bs-secondary);
}
.addSchoolBtn {
    grid-column: span 2;
    padding: 10px 0;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    border: none;
    cursor: pointer;
    background: var(--bs-secondary);
    font-weight: 600;
}
.addSchoolBtn:hover {
    background: var(--bs-theme-btn-hover);
}

.addSchoolFile {
    grid-column: span 2;
    background: var(--bs-white);
}
.backHome {
    border-radius: 5px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    background: var(--bs-theme-secondary);
    font-weight: 600;
    color: var(--bs-white);
    box-shadow: 0px 0px 5px 0px var(--bs-gray-500);
    padding: 0.5em 1em;
    transition: all ease-in-out 0.3s;
    border: 2px solid var(--bs-theme-secondary);
    text-decoration: none;
}
.backHome:hover {
    background: var(--bs-white);
    color: var(--bs-theme-secondary);
}
@media only screen and (max-width: 992px) {
    .addSchoolForm {
        width: 90%;
        grid-template-columns: repeat(1,1fr);
    }
    .addSchoolBtn,.addSchoolFile {
        grid-column: unset;
    }
}