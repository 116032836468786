.productCards {
    /* background: var(--bs-white) ; */
    position: relative;
}
.productCards .productCardLinks {
    text-decoration: none;
    font-weight:  600;
}


.productCards .productCardLinks .productCardFirsts {
    height: 70%; 
    width: 100%;
    overflow: hidden;
}
.productCards .productCardLinks .productCardFirsts .productCardFirstsImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}


.productCards .productCardLinks .productCard-body{
    padding: 10px;
}
.productCards .productCardLinks .productCard-body .productCardSeconds{
    line-height: 20px;
}
.productCards .productCardThirds{
    display: flex;
    padding: 10px;
    justify-content: space-between;
    text-decoration: none;
    margin: 0.3rem 0 0;
    color: var(--bs-theme-secondary); 
    /* align-items: center; */
}
.productCards .productCardThirds .product-cartBtn{
    border: 2px solid var(--bs-theme-secondary);
    background: transparent;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    display: flex;
    color: var(--bs-theme-secondary);
    align-items: center;
}
.productCards .productCardThirds .product-cartBtn:hover {
    background: var(--bs-theme-secondary);
    color: var(--bs-white);
}
.productCards .productCardLinks .productCard-body .productCardSeconds{ 
    text-decoration: none;
    margin: 0.5rem 0 0;
    color: var(--bs-theme-secondary); 
}


.productCards .productCardFourths {
    display: flex;
    position: absolute;
    align-items: center;
    width: 100%;
    padding: 10px 5px;
    justify-content: flex-end;
    top: 0;
    left: 0;
}
.productCards .productCardFourths .cardWishlist {
    background: transparent;
    border: none;
    color: var(--bs-theme-secondary);
}
.productCards .productCardFourths .cardDiscounts {
    background: var(--bs-theme-secondary);
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 20px;
    color: var(--bs-white);
}
