.schoolBannerContainer {
    /* padding: 10px 1rem; */
    background: var(--bs-white);
    display: flex;
    margin-bottom: 1em;
    color: var(--bs-theme-secondary);
    /* align-items: center; */
    border-radius: 10px;
    box-shadow: var(--bs-theme-box-shadow);
    /* justify-content: space-evenly; */
}
.orderConfirmOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: var(--bs-theme-bg);
    opacity: 1;
    /* background-color: rgba(128, 128, 128, 1); Light grey color with 50% opacity */
    width: 100%;
    height: 100%;
}
.schoolBannerContainer .schoolContent .schoolContentUp h2,h5 {
    margin: 0;
}
.schoolContent {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 1rem;
}

.schoolBannerContainer .schoolContent .schoolContentUp hr{
    height: 3px;
    border: none;
    background:var(--bs-theme-primary);
    width: 100%;
    outline: none;
}
.schoolBannerContainer .schoolContent .schoolContentDown .displayBox hr{
    height: 3px;
    border: none;
    background:var(--bs-theme-primary);
    width: 100%;
    outline: none;
}

.schoolBannerContainer .schoolContent .schoolContentUp{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.schoolBannerContainer .schoolContent .schoolContentDown .displayBox {
    text-align: center;
    width: fit-content;
}
.schoolBannerContainer .schoolContent .schoolContentDown .displayBox:nth-child(2){
    justify-self: end;
}
.schoolBannerContainer .schoolContent .schoolContentDown .displayBox h3,h5,hr{
    margin: 0;
}
.schoolBannerContainer .schoolContent .schoolContentDown {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2,1fr);
}
.schoolContainer .schoolImgBanner {
    height: 40vh;
    width: 40%;
    margin-right: 3em;
}

.schoolContainer .schoolImgBanner img{
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    width: 100%;

}
.schoolContainer h3 {
    text-align: center;
}

.schoolContainer .schoolBooksContainer {
    padding: 10px 10px 0 10px ;
    box-shadow: var(--bs-theme-box-shadow);
    margin-bottom: 2em;
    border-radius: 10px;
}
.tableBuy {
    width: 100%;
    padding: 15px 0;
    display: flex;
    justify-content: flex-end;
}
.tableBuy .tableBtnBuy {
    padding: 10px 0;
    cursor: pointer;
    width: 30%;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    border: 2px solid var(--bs-theme-secondary);
    border-radius: 5px;
    color: var(--bs-white);
    background: var(--bs-theme-secondary);
}
.tableBuy .tableBtnBuy:hover {
    color: var(--bs-theme-secondary);
    background: var(--bs-white);
}


.schoolContainer .schoolTable {
    width: 100%;
    border-collapse: collapse;
}
.schoolContainer .schoolTable thead select {
    width: 100%;
    outline: none;
    height: 100%;
    font-weight: bold;
    color: var(--bs-theme-secondary);
    border: none;
    padding: 0 10px;
}

.schoolContainer .schoolTable thead tr:nth-child(1){
    /* margin-bottom: 10em; */
    background: var(--bs-white);
    box-shadow: var(--bs-theme-box-shadow);
}
.tableBorderRadius {
    border-radius: 10px;
}
.tableBorderRadius {
 border-radius: 10px !important;
}

.schoolContainer .schoolTable tbody {
    background: var(--bs-white);
    border-radius: 10px;
    box-shadow: var(--bs-theme-box-shadow);
}
.schoolContainer .schoolTable tbody tr td {
    padding: 10px 0;
}
.schoolTableBookTitle {
    padding: 10px 10px 10px 0 !important;
}
.schoolContainer .schoolTable tbody tr th {
    padding: 10px 5px 10px 0;
}
.schoolContainer .schoolTable tr:first-child {
    color:  var(--bs-theme-secondary);
    text-align: left;
}
.schoolContainer .schoolTable tr:first-child th:first-child {
    text-align: center;
    padding: 10px;
}
.schoolContainer .schoolTable tr:not(:first-child) {
    color:  E6E6FA;
    font-weight: 500;
}
.schoolContainer .schoolTable tr:nth-child(even) {
    background-color:  #E6E6FA;
    font-weight: 500;
}
.schoolContainer .schoolTable tbody tr th input {
    height: 20px;
}
.tableSelect {
    background: transparent;
}


.schoolContainer .schoolTable tbody tr:nth-last-child(1) th {
    /* background: red; */
    border-radius: 0 0 0 10px;
}
.schoolContainer .schoolTable tbody tr:nth-last-child(1) td:last-child {
   /* background: red; */
    border-radius: 0 0 10px 0;
}
.borderLeft {
    border-radius: 10px 0px 0px 0px;
}
.borderRight {
    border-radius: 0px 10px 0px 0px;
}
.tablepadding{
    padding: 10px 20px;
}

@media only screen and (max-width: 992px) { 
    .schoolBannerContainer { 
        flex-direction: column;
        
    }
    .schoolContainer .schoolImgBanner {
        width: 100%;
        padding: 5px;
        height: unset;
    }
    .schoolContent {
        width: 100%;
        padding: 0.5rem 1.5rem 1.5rem 1.5rem;
        font-size: 14px;
    }
    .schoolContainer .schoolBooksContainer { 
        overflow-x: auto;
        padding: 10px 5px 0 5px;
    }
    .schoolContainer .schoolTable { 
        width: 100%;
    }
    .schoolContainer .schoolTable tbody {  
        font-size: 12px;
    }
}
