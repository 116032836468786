.promotionInputBorder {
    padding: 10px 10px;
    border-radius: 5px;
    border: 2px solid var(--bs-theme-secondary);
}
.promotionInputTitle{
    margin: 0;
}
.promotionContainer .promotionHeader {
    color: var(--bs-theme-secondary);
}
.addPromotion {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    cursor: pointer;
    padding: 8px 0;
    gap: 0.5rem;
    border: none;
    border-radius: 5px;
    box-shadow: var(--bs-theme-box-shadow);
    font-size: 1rem;
    transition: all 0.2s ease-in;
    color: var(--bs-white);
    background: var(--bs-theme-secondary);
}
.addPromotion:hover {
    color: var(--bs-theme-secondary);
    background: var(--bs-secondary);
}
.promotionContainer .promotionCreate {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    border: 2px solid var(--bs-theme-secondary);
    padding: 1rem 2rem;
    background: var(--bs-white);
    border-radius: 5px;
    z-index: 1000;
    color: var(--bs-theme-secondary);
}
.promotionCreate .promotionCreateHeading {
    display: flex;
    justify-content: space-between;
    color: var(--bs-theme-secondary);
}

.promotionContainer .promotionCreate .promotionForm {
    display: grid;
    margin-top: 1.5rem;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
}
.promotionContainer .promotionCreate .promotionForm .promotionInputGroup{ 
    display: flex;
    flex-direction: column;
}
.promotionContainer .promotionCreate .promotionForm .promotionDesc .promotionInputDescription { 
    border: 2px solid var(--bs-theme-secondary);
    color: var(--bs-theme-secondary) !important;
    outline: none;
    font-family: "Poppins";
    padding: 10px 10px;
    border-radius: 5px;
}
.promotionCreate .promotionForm .promotionInputGroup .promotionInputImage {
    background-color: var(--bs-white);
}
.promotionForm .promotionDesc,.promotionSubmitBtn,.promotionImageContainer {
    grid-column: span 3;
}
.promotionForm .promotionSubmitBtn {
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    color: var(--bs-theme-secondary);
    background-color: var(--bs-secondary);
    border: 2px solid var(--bs-secondary);
    transition: all 0.2s ease-in-out;

}
.promotionForm .promotionSubmitBtn:hover { 
    color: var(--bs-theme-bg);
    border: 2px solid var(--bs-theme-secondary);
    background-color: var(--bs-theme-secondary);
}
.promotionContainer .promotionsList {
    display: grid;
    margin: 1rem 0;
    gap: 1rem;
    grid-template-columns: repeat(4,1fr);
}
.promotionCreateOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: 999;
}
@media only screen and (max-width: 992px) {
    .promotionContainer .promotionCreate .promotionForm { 
        grid-template-columns: repeat(1,1fr);
    }
    .promotionForm .promotionDesc,.promotionSubmitBtn,.promotionImageContainer {
        grid-column: unset;
    }
    .promotionContainer .promotionCreate {
        padding: 2rem 1rem;
    }
    .promotionContainer .promotionsList {
        grid-template-columns: repeat(1,1fr);
    }
    .promotionContainer .promotionCreate {
        width: 90%;
        padding: 1rem 2rem;
    }
    .addPromotion {
        width: 100%;
        gap: 0.5rem;
    }
}