.editBooksContainer {
    position: relative;
}
.display-none {
    display: none !important;
}
.editBooksContainer .editForm {
    position: fixed;
    top: 0;
    overflow: auto;
    transform: translateX(-50%,-50%);
    left: 0;
    height: max-content;
    width: 100%;
    background: white;
    height: 100%;
    z-index: 999;
}
.editForm .editFormHeading {
    display: flex;
    width: 80%;
    color: var(--bs-theme-secondary);
    align-items: center;
    margin: auto;
    justify-content: space-between;
}

.editForm .editFormHeading .editFormClose{
    height: max-content;
    display: flex;
    align-items: center;
    padding: 5px;
    color: var(--bs-theme-secondary);
    background: transparent;
    border: 2px solid var(--bs-gray-400);
    cursor: pointer;
    transition: all ease-out 0.2s;
    border-radius: 5px;
}
.editForm .editFormHeading .editFormClose:hover {
    color: white;
    background: var(--bs-theme-secondary);
    border: 2px solid var(--bs-gray-400);
}

.inp-group{
    position: relative;
    display:flex;
    flex-direction: column;
}
.inp-group .editBooksInp {
    padding: 12px;
    transition: all 0.1s ease-in-out;
    border-radius: 5px;
    color: var(--bs-theme-secondary);
    border: none;
    outline: 1px solid var(--bs-secondary);
    font-size: 1em;
}
.inp-group .editBooksTitle {
    position: absolute;
    letter-spacing: 2px;
    font-weight: 500;
    pointer-events: none;
    font-size: 15px;
    left: 0;
    color: var(--bs-theme-secondary);
    padding: 0 10px 0 10px;
    background: #fff;
}

.inp-group .editBooksInp:focus {
    border: none;
    outline: 3px solid var(--bs-theme-secondary);
}
.inp-group .editBooksInp:focus ~ .editBooksTitle {
    background: var(--bs-theme-secondary);
    color: #fff;
}

.inp-group .editBooksInp:valid ~ .editBooksTitle,
.inp-group .editBooksInp:focus ~ .editBooksTitle {
    transform: translateX(5px) translateY(-15px);
}



.editBooksContainer .edit-books {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4,1fr);
}

.editBooksContainer .deleteForm {
    position: fixed;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    padding: 0.5rem;
    height: max-content;
    width: 30%;
    background: #fff;
    z-index: 999;
}

.deleteForm .deleteFormClose{
    height: max-content;
    display: flex;
    align-items: center;
    align-self: flex-end;
    padding: 5px;
    color: var(--bs-theme-secondary);
    background: transparent;
    border: 2px solid var(--bs-gray-400);
    cursor: pointer;
    transition: all ease-out 0.2s;
    border-radius: 5px;
}
.deleteForm .deleteFormContent{
    width: 100%;
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 1rem;
}
.deleteForm .deleteFormContent .deleteFormHeading{
    font-size: 1.5rem;
    color: var(--bs-theme-secondary);
    align-self: center;
}
.deleteForm .deleteFormContent .deleteFormButton{
    display:grid;
    gap: 1rem;
}
.deleteForm .deleteFormContent .deleteFormButton .deleteBoxBtn{
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid var(--bs-gray-400);
}
.deleteFormButton .deleteYesBtn{
    color: var(--bs-theme-bg);
    background: var(--bs-theme-secondary);
}
.deleteFormButton .deleteNoBtn{
    color: var(--bs-theme-bg);
    background: var(--bs-danger);
}

@media only screen and (max-width: 992px) {
    .editBooksContainer .editForm {
        padding: 0 0 20px;
    }
    .editBooksContainer .edit-books {
        gap: 20px 10px;
        grid-template-columns: repeat(2,1fr);
    }
    .editBooksContainer .deleteForm {
        padding: 0.5rem;
        height: max-content;
        width: 80%;
        background: #fff;
        z-index: 999;
    }
}