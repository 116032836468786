.cartConfirmContainer {
    /* border: 2px solid var(--bs-secondary); */
    width: 60%;
    height: 90vh;
    overflow: auto;
    border-radius: 5px;
    padding: 0.5rem 2rem;
    position: fixed;
    left: 50%;
    border: 2px solid var(--bs-theme-secondary);
    z-index: 1000;
    top: 50%;
    background: var(--bs-theme-bg);
    transform: translate(-50%,-50%);
}
.cartConfirmContainer::before {
    content: "";
    z-index: 999;
    background-color: var(--bs-gray-700);
    width: 100%;
    height: 100vh;
}

.cartInputBox {
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
}
.cartInput {
    height: 2rem;
}
.cartButtonBox {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}
.cartButtonBox .cartTotal{
    font-size: 15px;
} 
.confirmCartButton {
    padding: 10px 60px;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    border: 2px solid #7E41B3;
    border-radius: 5px;
    color: var(--bs-white);
    background: var(--bs-theme-secondary);
}
.confirmCartButton:hover {
    background: #7E41B3;
}
.cancelCartButton{
    padding: 10px 60px;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    border: 2px solid #7E41B3;
    border-radius: 5px;
    background: var(--bs-white);
    color: var(--bs-theme-secondary);
}
.cancelCartButton:hover {
    background: #7E41B3;
    /* color: var(--bs-theme-secondary); */
    color: var(--bs-white);
}

.cartBoxTitle {
    margin: 0;
}
.displayCartBox {
    display: block;
}
.hideCartBox {
    display: none;
}
.selectedBooksTableContainer {
    border-radius: 10px;
    box-shadow: var(--bs-theme-box-shadow);
    margin-bottom: 2em;
    overflow-x: auto;
    /* padding: 10px 10px 0; */
}
.selectedBooksTableContainer .selectedBooksTable {
    border-collapse: collapse;
    width: 100%;
}

.selectedBooksTable .selectedBooksTableHead{
    background: var(--bs-white);  
}
.selectedBooksTable .selectedBooksTableHead .selectedBooksTableHeadTr{
    color: var(--bs-theme-secondary);
}
.cartConfirmTd { 
    padding: 15px 10px !important;
}

.selectedBooksTable .selectedBooksTableHead .selectedBooksTableHeadTr th:first-child{
    padding: 10px;
}
.selectedBooksTable .selectedBooksTableHead .selectedBooksTableHeadTr th:not(:first-child){
    text-align: left;
}
.selectedBooksTable .selectedBooksTableHead .selectedBooksTableHeadTr th:last-child{
    text-align: center;
}

.selectedBooksTable .selectedBooksTableBody{
    background: var(--bs-white);
    border-radius: 10px;
}

.selectedBooksTable .selectedBooksTableBody .selectedBooksTableBodyTr{
    font-weight: 500;
}
.selectedBooksTable .selectedBooksTableBody tr:nth-child(odd){
    background: #e6e6fa;
}

.selectedBooksTable .selectedBooksTableBody .selectedBooksTableBodyTr td:last-child{
    text-align: center;
}
.selectedBooksTable .selectedBooksTableBody tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
}
.selectedBooksTable .selectedBooksTableBody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}
.booksAddBtn, .booksSubBtn {
    align-items: center;
    background: #0000;
    /* border: 2px solid var(--bs-theme-secondary); */
    border: none;
    border-radius: 5px;
    color: var(--bs-theme-secondary);
    display: inline-flex;
}
@media only screen and (max-width: 992px) {
    .cartConfirmContainer { 
        width: 90%;
        padding: 0.5rem 2rem;
    }
    .selectedBooksTableContainer .selectedBooksTable {
        border-collapse: collapse;
        width: max-content;
    }
    .confirmCartButton, .cancelCartButton{
        padding: 10px 15px;
    }
    /* .selectedBooksTable .selectedBooksTableBody .selectedBooksTableBodyTr td:nth-child(2) {
       
        width: 20px;
    } */
}